html,
body,
div#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.Container {
  padding-top: 2rem;
}

.Form {
  max-width: 12rem;
  margin: auto;
}

.navLink {
  padding: 0 5px;
}

.nav {
  @media screen {
    max-width: 200px;
  }
}

.tdLeftStyleRecipes {
  cursor: pointer;
  text-align: left;
  width: 100%;
}

.tdRightStyleRecipes {
  text-align: right;
  width: 5%;
}

.hover {
  cursor: pointer;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.text-align-left {
  text-align: left;
}
